<template>
  <div class="scrollable">
    <div class="data-feeds">
      <div class="dfr header">
        <div class="dfr__col">
          <span>{{ texts[0].label }}</span>
        </div>
        <div class="dfr__col mobile-hide">
          <span>{{ texts[1].label }}</span>
        </div>
        <div class="dfr__col mobile-hide">
          <span>{{ texts[2].label }}</span>
        </div>
        <div class="dfr__col mobile-hide">
          <span>{{ texts[3].label }}</span>
        </div>
        <div class="dfr__col">
          <span>{{ texts[4].label }}</span>
        </div>
        <div />
      </div>

      <DeployerListOracleFeedRow
        v-for="(item, index) in props.feeds"
        :key="`feed-${index}`"
        :item="item"
        :oracle="oracle"
        :read-mode="props.readMode"
      />
    </div>
  </div>
</template>

<script setup>
const route = useRoute()

const props = defineProps({
  feeds: Array,
  labels: Array,
  loading: {
    type: Boolean,
    default: false
  },
  oracle: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds.table_labels
})

const oracle = ref({
  address: props.oracle.address,
  chainId: props.oracle.chainId
})

const countHeartbeat = (heartbeat) => {
  if (heartbeat < 120) {
    return `${heartbeat}s`
  } else if (heartbeat < 3600) {
    return `${Math.floor(heartbeat / 60)}m`
  } else {
    return `${Math.floor(heartbeat / 3600)}h`
  }
}
</script>

<style lang="scss" scoped>
.scrollable {
  @media (max-width: 1023px) {
    overflow-x: auto;
  }
}

.data-feeds {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));

  @media (max-width: 1023px) {
    // overflow-x: auto;
    margin-top: 10px;
  }

  :deep(.dfr) {
    display: grid;
    width: 100%;
    grid-gap: 8px;
    grid-template-columns: 21% 18% 19% 18% 18% auto;
    align-items: center;
    padding-left: 20px;
    padding-right: 50px;
    padding: 25px 20px 20px;
    line-height: 100%;
    font-size: 1.5rem;
    font-weight: 500;

    @media (max-width: 1023px) {
      grid-template-columns: auto 100px;
      grid-gap: 0 8px;
    }

    .mobile-hide {
      @media (max-width: 1023px) {
        display: none;
      }
    }

    &.header {
      border-bottom: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
      background: var(--Banner-5, rgba(0, 0, 0, 0.05));

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      span {
        opacity: 0.5;
        font-weight: 500;
        letter-spacing: 0.15px;
      }
    }

    .dfr__col {
      position: relative;
      z-index: 2;
    }
    &.data-row {
      background: url(/images/oracle/arrow-right-white.svg) no-repeat right 30px
        center var(--customer-color);
      min-height: 50px;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-block: 20px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: -5px;
        height: 1px;
        background: var(--bubble-10, rgba(0, 0, 0, 0.1));
      }

      &:hover {
        background: var(--Banner-5, rgba(0, 0, 0, 0.05));
      }

      &:last-child {
        margin-bottom: 5px;
        &::after {
          display: none;
        }
      }

      .asset {
        display: flex;
        align-items: center;
        gap: 14px;

        .asset-icons {
          position: relative;
          img {
            display: block;
          }
        }

        .second-icon {
          position: absolute;
          bottom: -2px;
          right: -2px;
        }
      }
    }
  }
}
</style>
