<template>
  <div>
    <div class="page-title page-title--oracles">{{ oraclesTitle }}</div>
    <div class="switch">
      <div class="switch__left">
        <button
          type="button"
          class="switch-btn"
          :class="{
            active: newOraclesStore.selectedOraclesTab === 'ecosystem'
          }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'ecosystem'"
          v-if="showSwitch"
        >
          <div v-html="EcosystemOraclesSvg" />
          Ecosystem oracles
          <span class="count">{{ ecosystemOracles.length }}</span>
        </button>
        <button
          type="button"
          class="switch-btn"
          :class="{ active: newOraclesStore.selectedOraclesTab === 'user' }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'user'"
        >
          <div v-html="UserOraclesSvg" />
          My oracles <span class="count">{{ userOraclesMain.length }}</span>
        </button>
      </div>
      <div class="switch__right">
        <button
          type="button"
          class="switch-btn"
          :class="{ active: newOraclesStore.selectedOraclesTab === 'deleted' }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'deleted'"
        >
          <div v-html="DeletedOraclesSvg" />
          Deleted oracles
        </button>
      </div>
    </div>

    <div
      v-show="newOraclesStore.selectedOraclesTab === 'ecosystem'"
      v-if="ecosystemOracles.length > 0"
      class="list"
    >
      <!-- TODO: Kamil - please set read-mode on true if user should see simpliefied version -->
      <DeployerListOracle
        v-for="oracle in ecosystemOracles"
        :key="oracle.address + oracle.chainId"
        :oracle="oracle"
        :read-mode="true"
      />
    </div>

    <div v-show="newOraclesStore.selectedOraclesTab === 'user'" class="list">
      <DeployerListNoOracles v-if="noOracles" />
      <template v-if="userOraclesMain?.length">
        <!-- <DeployerListOracleRowDraft :oracle="userOraclesMain[0]" /> -->
        <DeployerListOracle
          v-for="oracle in userOraclesMain"
          :key="oracle.address + oracle.chainId"
          :oracle="oracle"
        />
      </template>
    </div>
    <div v-show="newOraclesStore.selectedOraclesTab === 'deleted'" class="list">
      <DeployerListOracle
        v-for="oracle in userOraclesDeleted"
        :key="oracle.address + oracle.chainId"
        :oracle="oracle"
      />
    </div>
    <DeployerListDocuBar class="mt20" />
  </div>
</template>

<script setup>
import EcosystemOraclesSvg from '~/layers/base/assets/icons/ecosystem-oracles.svg?raw'
import UserOraclesSvg from '~/layers/base/assets/icons/user-oracles.svg?raw'
import DeletedOraclesSvg from '~/layers/base/assets/icons/delete.svg?raw'

const config = useRuntimeConfig()

// //TODO: Kamil - fetch users/ecosystem oracles (maybe it would be better to save those to store?)

const showSwitch = ref(true)
const whitelabelStore = useWhitelabelStore()
const newOraclesStore = useNewOraclesStore()

if (!whitelabelStore.config.ecosystem_oracles) {
  showSwitch.value = false
  newOraclesStore.selectedOraclesTab = 'user'
}

const oraclesTitle = computed(() => {
  if (newOraclesStore.selectedOraclesTab === 'ecosystem') {
    return 'Ecosystem oracles'
  } else if (newOraclesStore.selectedOraclesTab === 'user') {
    return 'My oracles'
  } else if (newOraclesStore.selectedOraclesTab === 'deleted') {
    return 'Deleted oracles'
  }
})

const ecosystemOracles = computed(() => {
  return [
    {
      address: '0x11E405e4432c615315C624159A42584A03DccAdF',
      chainId: 11155111,
      oracleId: 'Test Oracle Lending',
      status: 'Active',
      lastUpdate: '',
      network: 'Mainnet',
      gasLeft: '',
      priceFeeds: ''
    }
  ]
})

const userOracles = computed(() => {
  return newOraclesStore.oracles
    ? newOraclesStore.oracles.map((oracle) => ({
        address: oracle.Address,
        chainId: oracle.ChainID,
        oracleId: oracle.FeederID,
        active: oracle.Active,
        expired: oracle.Expired,
        deleted: oracle.Deleted,
        lastUpdate: oracle.LastUpdate,
        network: oracle.Network,
        gasLeft: oracle.GasLeft,
        priceFeeds: oracle.PriceFeeds,
        feederAddress: oracle.FeederAddress
      }))
    : []
})

const userOraclesMain = computed(() => {
  return userOracles.value.filter((oracle) => !oracle.deleted)
})

const userOraclesDeleted = computed(() => {
  return userOracles.value.filter((oracle) => oracle.deleted)
})

const noOracles = computed(() => {
  return !userOraclesMain.value.length
})
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: flex-end;

  &__left {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.switch-btn {
  padding: 10px;
  font-size: 1.5rem;
  color: var(--Text, #000);
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1.5px solid transparent;
  @media (max-width: 560px) {
    font-size: 1.3rem;
    padding: 10px 5px;
    gap: 5px;
    border-bottom: 1.5px solid transparent;
  }

  :deep(svg) {
    display: block;
    position: relative;
    top: -1px;
    @media (max-width: 560px) {
      width: 15px;
      height: 15px;
    }

    path {
      stroke: var(--Text, #000);
    }
  }

  .count {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 21px;
    border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
    background: var(--bubble-10, rgba(0, 0, 0, 0.1));
    line-height: 100%;

    @media (max-width: 560px) {
      width: 18px;
      height: 18px;
      font-size: 1rem;
      border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
      background: var(--CTA, #3d6ef6);
      color: var(--CTA-text, #fff);
      position: relative;
      top: -2px;
    }
  }

  &.active {
    font-weight: 500;
    letter-spacing: normal;
    border-bottom: 1.5px solid var(--Text, #000);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.page-title--oracles {
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
